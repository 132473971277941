const mapOptionList = [
  {
    name: 'Maptiler',
    url: process.env.REACT_APP_MAPTILER_URL,
  },
  {
    name: 'Google Maps Street',
    url: 'http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
  },
  {
    name: 'Google Maps Hybrid',
    url: 'http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',
  },
  {
    name: 'Google Maps Satellite',
    url: 'http://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
  },
]

export default mapOptionList
