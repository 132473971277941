import { grey } from '@mui/material/colors'

const primaryLacak = {
  light: '#4dace3',
  main: '#1e96dc',
  dark: '#008fe3',
}

const primaryGmm = {
  light: '#b2ebf2',
  main: '#75b08a',
  dark: '#75b08a',
}

const getPrimaryColor = () => {
  if (process.env.REACT_APP_DASHBOARD_TYPE === 'LACAK') return primaryLacak
  else if (process.env.REACT_APP_DASHBOARD_TYPE === 'GMM' 
    || process.env.REACT_APP_DASHBOARD_TYPE === 'CK'
    || process.env.REACT_APP_DASHBOARD_TYPE === 'JNT'
  ) return primaryGmm
}

export const colors = {
  primary: getPrimaryColor(),
  background: {
    default: '#F5F5F5'
  },
  drawer: {
    default: '#3D454E'
  },
  icon: {
    default: grey[500],
    dark: grey[600],
  },
  marker: {
    default: '#17A0D9',
    text: '#FFFFFF'
  }
}