import { useState, useContext, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { startCase } from 'lodash'

//COMPONENT
import AppBar from 'components/AppBar/AppBar'
import DataGridTable from 'components/Customs/CustomDataGrid'
import Flyout from 'components/Flyout/Flyout'
import LoadingPaper from 'components/LoadingPaper/LoadingPaper'
import Map from 'components/Map/Map'
// import VehicleDropdown from './VehicleDropdown/VehicleDropdown'

// CONSTANTS
import { colors } from 'constants/colors'
import { values } from 'constants/value'

// CONTEXT
import { AllPagesContext } from 'contexts/AllPagesContext'

// HOOKS
import useAxiosPrivate from 'hooks/useAxiosPrivate'

// MUIS
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

// MUI ICONS
import IconDownload from '@mui/icons-material/Download'
import IconLocalParking from '@mui/icons-material/LocalParking'
import IconNavigation from '@mui/icons-material/Navigation'
import IconStop from '@mui/icons-material/Stop'

// SERVICES
import { searchLocation } from 'services/geocoder'

// STYLES
import useLayoutStyles from './vehiclesUseStyles'

// UTILS
import { flattenObject } from 'utilities/objects'
import { getConnectionStatusColor } from 'utilities/component'
import { getCombinedTrackerData } from 'utilities/tracker'
import { getProcessedZoneList } from 'utilities/zone'
import { downloadTableData } from './vehiclesUtils'

const Vehicles = () => {
  const classes = useLayoutStyles()
  const axiosPrivate = useAxiosPrivate()
  const { hash } = useParams()
  
  // const refreshOptions = [
  //   {
  //     value: 3600,
  //     label: '1 hour',
  //   },
  //   {
  //     value: 43200,
  //     label: '12 hour',
  //   },
  //   {
  //     value: 86400,
  //     label: '24 hour',
  //   },
  // ]

  const initialColumns = [
    {
      field: 'group_title',
      headerName: 'Group',
      minWidth: 250,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'label',
      headerName: 'Label',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_connection_status',
      headerName: 'Connection Status',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Chip label={startCase(params.value.replace('_', ' '))} size='small' color={getConnectionStatusColor(params.value.replace('_', ' ').toUpperCase())} sx={{borderRadius: 1}}/>
        ),
    },
    {
      field: 'state_gps_movement_status',
      headerName: 'Movement Status',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
      renderCell: (params) =>
        params.value && (
          <Stack direction={'row'} alignItems='center'>
            {params.value.toUpperCase() === 'MOVING' && <IconNavigation className={classes.iconStatus} sx={(theme) => ({bgcolor: theme.palette.success.main})} />}
            {params.value.toUpperCase() === 'PARKED' && <IconLocalParking className={classes.iconStatus} sx={(theme) => ({bgcolor: theme.palette.primary.main})} />}
            {params.value.toUpperCase() === 'STOPPED' && <IconStop className={classes.iconStatus} sx={(theme) => ({bgcolor: theme.palette.error.main})} />}
            &nbsp;
            <Typography 
              variant='inherit'
              className='textCapitalize'
            >
              {params.value.replace('_', ' ')}
            </Typography>
          </Stack>
        ),
    },
    {
      field: 'odometer',
      headerName: 'Odometer',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'engine_hours',
      headerName: 'Engine Hours',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'address',
      headerName: 'GPS Address',
      minWidth: 250,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_location',
      headerName: 'GPS Location',
      minWidth: 250,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_signal_level',
      headerName: 'GPS Signal Level',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_updated',
      headerName: 'GPS Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_heading',
      headerName: 'Heading',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_speed',
      headerName: 'Speed',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gps_alt',
      headerName: 'Altitude',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gsm_updated',
      headerName: 'GSM Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gsm_signal_level',
      headerName: 'GSM Signal_Level',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_gsm_network_name',
      headerName: 'GSM Network_Name',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_battery_level',
      headerName: 'Battery Level',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_battery_update',
      headerName: 'Battery Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_1',
      headerName: 'Input 1',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_2',
      headerName: 'Input 2',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_3',
      headerName: 'Input 3',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_4',
      headerName: 'Input 4',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'input_5',
      headerName: 'Input 5',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_inputs_update',
      headerName: 'Inputs Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'output_1',
      headerName: 'Output 1',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'output_2',
      headerName: 'Output 2',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'output_3',
      headerName: 'Output 3',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'output_4',
      headerName: 'Output 4',
      minWidth: 150,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'state_outputs_update',
      headerName: 'Outputs Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
    },
    {
      field: 'lastUpdate',
      headerName: 'Last Update',
      minWidth: 180,
      hide: false,
      isFilterShown: true,
      isSortShown: true,
      valueGetter: params => params?.row?.state?.last_update
    }
  ]

  const initialFilters = {
    group: '',
    label: '',
    connection_status: '',
    movement_status: '',
    odometer: '',
    odometer_update: '',
    engine_hours: '',
    engine_hours_update: '',
    gps_address: '',
    gps_location: '',
    gps_signal_level: '',
    gps_update: '',
    heading: '',
    speed: '',
    altitude: '',
    gsm_update: '',
    gsm_signal_level: '',
    gsm_network_name: '',
    battery_level: '',
    battery_update: '',
    input_1: '',
    input_2: '',
    input_3: '',
    input_4: '',
    input_5: '',
    inputs_update: '',
    output_1: '',
    output_2: '',
    output_3: '',
    output_4: '',
    outputs_update: '',
  }

  // APP BAR
  const [pageSearch, setPageSearch] = useState('')

  // FLYOUT
  const [isFlyoutShown, setIsFlyoutShown] = useState(false)

  // CONTEXT
  const { 
    breakpointZoomBoundary, 
    setIsLoading,
  } = useContext(AllPagesContext)

  // CONTENT
  const [ isDataGridLoading, setIsDataGridLoading ] = useState(false)
  // DATA GRID - BASE
  const [ selectedColumnList, setSelectedColumnList ] = useState(initialColumns)
  const [ tableData, setTableData ] = useState([])
  // DATA GRID - PAGINATION
  const [ totalRow, setTotalRow ] = useState(0)
  const [ pageNumber, setPageNumber ] = useState(0)
  const [ pageSize, setPageSize ] = useState(500)
  // DATA GRID - ORDER
  const [ order, setOrder ] = useState(null)
  const [ orderBy, setOrderBy ] = useState(null)
  // DATA GRID - FILTER
  const [ isFilterOn, setIsFilterOn ] = useState(false)
  const [ filters, setFilters ] = useState(initialFilters)
  // DATA GRID - SELECTION
  const [ selectionModel, setSelectionModel ] = useState([])
  // DIALOG
  const [showDialog, setShowDialog] = useState(false)
  // MAPS
  const [selectedCoordinates, setSelectedCoordinates] = useState(tableData)
  const [center, setCenter] = useState(null)
  const [zones, setZones] = useState([])
  const [isGeofenceLoading, setIsGeofenceLoading] = useState(false)
  // REFRESH RATE
  // const [ refreshRate, setRefreshRate ] = useState(-1)

  const fetchVehicleList = useCallback(async (abortController, isMounted) => {
    setIsDataGridLoading(true)
    try {
      const result = await getCombinedTrackerData(abortController.signal, { hash }, true, colors.marker.default, axiosPrivate, setIsLoading)

      if (result?.status === 'failed') {
        setIsLoading(false)
        setIsDataGridLoading(false)
        return
      }

      const processedResult = await Promise.all(result.map(async (v) => {
        const { state, group } = v
        const { gps, gsm, inputs, outputs } = state || {}
  
        const groupObject = flattenObject(group, 'group_')
        const stateObject = flattenObject(state, 'state_')
        const gpsObject = flattenObject(gps, 'state_gps_')
        const gsmObject = flattenObject(gsm, 'state_gsm_')
        const inputObject = inputs?.reduce((obj, curr, idx) => ({
          ...obj,
          [`input_${idx}`]: curr
        }), {})
        const outputObject = outputs?.reduce((obj, curr, idx) => ({
          ...obj,
          [`output_${idx}`]: curr
        }), {})
  
        let address = ''
  
        try {
          address = (await searchLocation(abortController.signal, {
            hash,
            location: gpsObject.state_gps_location
          }, axiosPrivate))?.data.value
        } catch (error) {
          address = ''
        }

        let vehicleItem = {
          ...v,
          ...groupObject,
          ...stateObject,
          ...gpsObject,
          ...gsmObject,
          ...inputObject,
          ...outputObject,
          state_gps_movement_status: stateObject.state_movement_status,
          state_gps_connection_status: stateObject.state_connection_status,
          state_gps_location: gpsObject.state_gps_location ? `${gpsObject.state_gps_location.lat}, ${gpsObject.state_gps_location.lng}` : '',
          address
        }
  
        return vehicleItem
      }))
      
      if(isMounted) {
        setTableData(processedResult)
        setSelectedCoordinates(processedResult)
      }
    } catch (error) {
      console.log({ error })
    }
    setIsDataGridLoading(false)
  }, [hash, setIsLoading, axiosPrivate])

  const fetchZoneList = useCallback(async (abortController, isMounted) => {
    const result = await getProcessedZoneList(abortController.signal, {
      hash,
      sort:['label=asc'],
      offset:0,
      limit:500,
    }, axiosPrivate)
    
    if(isMounted) {
      setZones(result)
      setIsGeofenceLoading(true)
    }
  }, [hash, axiosPrivate])

  // SIDE EFFECT FETCHING DATA 
  useEffect(() => {
    let isMounted = true
    const abortController = new AbortController()

    fetchVehicleList(abortController, isMounted)
    fetchZoneList(abortController, isMounted)

    return () => {
      isMounted = false
      abortController.abort()
    }
  }, [
    filters, 
    // pageNumber, pageSize, 
    pageSearch, 
    order, orderBy, 
    fetchVehicleList, 
    fetchZoneList
  ])

  const handleCloseDialog = () => setShowDialog(false)
  
  const handleRowSelection = (currRow) => {
    const currRowData = tableData.find((v) => v.id === currRow[0])
    setCenter([currRowData.state_gps?.location?.lat, currRowData.state_gps?.location?.lng])
    setIsFlyoutShown(true)
  }

  useEffect(() => {
    if (selectionModel.length === 1) setIsFlyoutShown(true)
    else setIsFlyoutShown(false)
  }, [selectionModel])

  return (
    <>
      <AppBar
        className={'zoom'}
        pageTitle='Vehicles'
        hasSearch={false}
        // search={pageSearch}
        // setSearch={setPageSearch}
        hasFlyout={false}
        isFlyoutShown={isFlyoutShown}
        flyoutTitle='Vehicle Info'
        flyoutTitleMargin={breakpointZoomBoundary ? 300 : 252}
        onToggleFlyoutClick={() => setIsFlyoutShown((current) => !current)}
        extraComponent={
          <Button
            variant='contained'
            startIcon={<IconDownload color='inherit'/>}
            sx={{ color: theme => theme.palette.common.white }}
            onClick={() => downloadTableData(tableData)}
            color='success'
            disabled={isDataGridLoading}
          >
            Download CSV
          </Button>
        }
      />

      <Stack
        direction='row'
        position='relative'
        flex='1'
        className='contentContainer'
        sx={{
          paddingRight: isFlyoutShown ? `${values.flyoutWidth + 24}px` : 0,
          height: '100vh',
        }}
      >
        {/* MAIN CONTENT */}
        <LoadingPaper isLoading={isDataGridLoading} className={`${classes.tableContainer}`}>
          {/* <VehicleDropdown
            label='Refresh Table'
            value={refreshRate}
            handleChange={(e) => {
              setRefreshRate(e.target.value)
            }}
            options={refreshOptions}
          /> */}

          <DataGridTable
            // BASE
            columns={initialColumns}
            selectedColumnList={selectedColumnList}
            setSelectedColumnList={setSelectedColumnList}
            rows={tableData}
            // getRowId={(row) => row.id}
            // PAGINATION
            total={totalRow}
            paginationModel={{ page: pageNumber, pageSize }}
            onPaginationModelChange={(model, details) => {
              setPageNumber(model.page)
              setPageSize(model.pageSize)
            }}
            // ORDER
            setOrder={setOrder}
            order={order}
            setOrderBy={setOrderBy}
            orderBy={orderBy}
            // FILTER
            setFilters={setFilters}
            isFilterOn={isFilterOn}
            onRowSelectionModelChange={handleRowSelection}
            checkboxSelection={false}
          />
        </LoadingPaper>

        {/* SIDE CONTENT */}
        <Flyout isFlyoutShown={isFlyoutShown} flyoutWidth={values.flyoutWidth} className={`${classes.vehicleFlyout}`}>
          <Map
            zones={zones}
            isGeofenceLoading={isGeofenceLoading}
            selectedCenter={center} 
            isWithFullscreen={true}
            handleFullscreenClick={() => setShowDialog(true)}
            coordinates={selectedCoordinates.map(v => {
              return {
                coordinate: [v.state_gps?.location?.lat, v.state_gps?.location?.lng],
                radius: v.state_gps_signal_level < 50 ? null : v.state_gps_signal_level,
                label: v.label,
                color: v.group.color ?? v.group_color
              }
            })}
          />
        </Flyout>
      </Stack>

      <Dialog open={showDialog} onClose={handleCloseDialog} fullWidth={true} maxWidth='lg' keepMounted>
        <Stack className={`${classes.dialogContainer}`} direction='column'>
          <Map
            zones={zones}
            isGeofenceLoading={isGeofenceLoading}
            selectedCenter={center} 
            isWithFullscreen={false}
            handleFullscreenClick={() => setShowDialog(true)}
            coordinates={selectedCoordinates.map(v => ({
              coordinate: [v.state_gps?.location?.lat, v.state_gps?.location?.lng],
              radius: v.state_gps_signal_level < 50 ? null : v.state_gps_signal_level,
              label: v.label,
              color: v.group.color ?? v.group_color
            }))}
          />
          <Button variant='text' onClick={handleCloseDialog} className={`${classes.closeButton}`}>Close</Button>
        </Stack>
      </Dialog>
    </>
  )
}

export default Vehicles
